import { useMemo, useContext, useState } from 'react';
import { paths } from 'src/routes/paths';
import { useTranslate } from 'src/locales';
import { AdminContext } from 'src/context/AdminContext';
import { SiBitcoin, SiLitecoin } from 'react-icons/si';
import SvgColor from 'src/components/svg-color'; // Adjust the import path

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  motorcycles: icon('ic_motorcycles'),
  users: icon('ic_users'),
  services: icon('ic_services'),
  productstype: icon('ic_productstype'),
  newsletter: icon('ic_newsletter'),
  brands: icon('ic_brands'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();
  const { isAdmin } = useContext(AdminContext);

  const [selectedIcon, setSelectedIcon] = useState('bitcoin'); // Default to Bitcoin

  const toggleIcon = (iconName) => {
    setSelectedIcon(iconName); // Toggle between 'bitcoin' and 'litecoin'
  };

  const data = useMemo(() => {
    // Function to get the text color based on selected coin
    const getTextColor = () => selectedIcon === 'bitcoin' ? '#f7931a' : '#345D9D';

    const bitcoinItems = [
      {
        title: t('Total Hashrate'),
        path: paths.dashboard.accounthistory.root,
        icon: ICONS.order,
      },
      {
        title: t('Indiv Miner Hashrate'),
        path: paths.dashboard.minerrate.root,
        icon: ICONS.label,
      },
      {
        title: t('Profit Summary'),
        path: paths.dashboard.accountprofit.root,
        icon: ICONS.blog,
      },
      {
        title: t('Rewards History'),
        path: paths.dashboard.paymenthistory.root,
        icon: ICONS.user,
      },
    ];


    const litecoinItems = [
      {
        title: t('Total Hashrate'),
        path: paths.dashboard.litecoinhashrate.root,
        icon: ICONS.order,
      },
      {
        title: t('Indiv Miner Hashrate'),
        path: paths.dashboard.litecoinminerrate.root,
        icon: ICONS.label,
      },
      {
        title: t('Profit Summary'),
        path: paths.dashboard.litecoinaccountprofit.root,
        icon: ICONS.blog,
      },
      {
        title: t('Rewards History'),
        path: paths.dashboard.litecoinpaymenthistory.root,
        icon: ICONS.user,
      },
    ];

    // Combine the Bitcoin and Litecoin items conditionally based on selectedIcon
    const selectedItems = selectedIcon === 'bitcoin' ? bitcoinItems : litecoinItems;

    return [
      {
        subheader: t('Renegade Hall'),
        items: [
          {
            title: t('Overview'),
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.dashboard,
          },
          {
            title: t('Whitepaper'),
            path: 'https://royal-labs-llc.gitbook.io/uturenegades',
            icon: ICONS.file,
          },
          {
            title: t('Voting & Proposals'),
            path: paths.dashboard.votingsystem.root,
            icon: ICONS.external,
          },
          {
            title: t('Staking Dashboard'),
            path: paths.dashboard.stakingsystem.root,
            icon: ICONS.analytics,
          },
          isAdmin
            ? {
                title: t('Admin System'),
                path: paths.dashboard.adminsystem.root,
                icon: ICONS.file,
              }
            : null,
        ].filter(Boolean),
      },
      {
        subheader: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center the icons horizontally
              alignItems: 'center',
              gap: '16px',
              width: '100%', // Ensure the container takes up the full width
            }}
          >
            <button
              type="button" // Explicit type added
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: '8px',
                marginBottom: '15px',
                background: 'transparent',
                border: 'none',
                padding: 0,
              }}
              onClick={() => toggleIcon('bitcoin')}
              aria-label="Select Bitcoin"
            >
              <SiBitcoin size={46} color="#f7931a" />
            </button>

            <button
              type="button" // Explicit type added
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: '8px',
                marginBottom: '15px',
                background: 'transparent',
                border: 'none',
                padding: 0,
              }}
              onClick={() => toggleIcon('litecoin')} // Select Litecoin
              aria-label="Select Litecoin"
            >
              <SiLitecoin size={46} color="#345D9D" />
            </button>
          </div>
        ),
        items: selectedItems.map(item => ({
          ...item,
          title: (
            <span style={{ color: getTextColor() }}>
              {item.title}
            </span>
          ),
        })), // Apply the text color to each item's title
      },
    ];
  }, [t, isAdmin, selectedIcon]); // Re-run whenever selectedIcon changes

  return data;
}
