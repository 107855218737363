import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useMockedUser } from 'src/hooks/use-mocked-user';

import { hideScroll } from 'src/theme/css';

import Logo from 'src/components/logo';
import { NavSectionMini } from 'src/components/nav-section';

import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import NavToggleButton from '../common/nav-toggle-button';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useMockedUser();

  const navData = useNavData();
  
  const BottomImages = () => (
    <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginLeft: '20px' }}>
      <Link to="/dashboard/founders" style={{ textDecoration: 'none' }}>
        <img
          src="/assets/tate.jpg"
          alt="Tate On Chain Twitter Profile"
          width={50}
          height={50}
          style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover', marginTop: '20px' }} // Applied border radius for circle
        />
      </Link>
      <Link to="/dashboard/founders" style={{ textDecoration: 'none' }}>
        <img
          src="/assets/mellow.jpg"
          alt="Mellow 080 Twitter Profile"
          width={50}
          height={50}
        style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover', marginTop: '20px' }} // Applied border radius for circle
        />
      </Link>
    </div>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />

        <NavSectionMini
          data={navData}
          slotProps={{
            currentRole: user?.role,
          }}
        />
      <BottomImages/>
      </Stack>
    </Box>
  );
}
